import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { Feeder } from "../../../zecs/zecs/zec";
import { DataMarker } from "../../map";

@Component({
    selector: "app-feeder-content",
    templateUrl: "./feeder-content.component.html"
})
export class FeederContentComponent implements OnChanges {
    @Input() feeder: Feeder;
    @Input() canEdit: boolean;
    @Input() dataMarker: DataMarker;
    pwVisible = false;

    dataMarkerLatLng() {
        if (this.dataMarker) {
            const latlng = this.dataMarker.getLatLng();
            return { lat: latlng.lat, lng: latlng.lng };
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.feeder) {
            if (changes.feeder.previousValue && changes.feeder.currentValue) {
                if (changes.feeder.previousValue.id !== changes.feeder.currentValue.id) {
                    this.pwVisible = false;
                }
            }
        }
    }
}
