<div class="form-group row">
    <div class="col">
        <zx-source [model]="source" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="true"></zx-source>
    </div>
</div>
<div class="form-group row">
    <h5 class="col-12" translate>INGEST_CLUSTER</h5>
    <div class="col-12">
        <span *ngIf="source.status?.active_broadcaster as activeBroadcaster">
            <zx-broadcaster-link [id]="activeBroadcaster.id" [name]="activeBroadcaster.name" [clusterId]="source.broadcaster_cluster_id"></zx-broadcaster-link>
            @
        </span>
        <zx-cluster
            *ngIf="source.inputCluster"
            class="d-inline-block"
            [model]="source.inputCluster"
            [showOtherIcons]="false"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="false"
        ></zx-cluster>
    </div>
</div>
<div class="form-group row" *ngIf="source.view_url && canVLC">
    <div class="col-12">
        <a href="/play/source/{{ source.playId }}" target="_blank" class="btn btn-play btn-primary"><fa-icon icon="play" size="xs"></fa-icon></a>
        <small>
            ({{ "REQUIRES" | translate }} <a target="_blank" id="downloadVLC" href="http://www.videolan.org/vlc/">{{ "VLC" | translate }}</a>
            {{ "WITH_ZIXI_PLUGIN" | translate }}
            <a target="_blank" id="downloadWindows" href="https://portal.zixi.com/free/zixi_vlc_plugin-win-latest.zip">{{ "WINDOWS" | translate }}</a
            >, <a target="_blank" id="downloadMac" href="https://portal.zixi.com/free/zixi_vlc_plugin-mac-latest.tar.gz">{{ "MAC" | translate }}</a
            >)
        </small>
    </div>
</div>
<div class="form-group row" *ngIf="source.webrtc_mode !== ''">
    <div class="col-12">
        <a [routerLink]="['/' + urls.sources, source.id | uId, source?.name, 'webrtc']" target="_blank" class="btn btn-play btn-primary"
            ><fa-icon icon="play" size="xs"></fa-icon
        ></a>
        <small> WebRTC </small>
    </div>
</div>
<div class="form-group row" *ngIf="source.resourceTags && source.resourceTags.length">
    <h5 class="col-12" translate>TAGS</h5>
    <div class="col-12">
        <span *ngFor="let tag of source.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="source.alertingProfile">
    <h5 class="col-12" translate>ALERTING_PROFILE</h5>
    <div class="col-12">
        <span>{{ source.alertingProfile.name }}</span>
    </div>
</div>

<div class="form-group row">
    <h5 class="col-12" translate>TYPE</h5>
    <div class="col-12">
        <span *ngIf="!source.protocol && source.type !== 'mediaconnect'">{{ source.type | desnake }}</span>
        <span *ngIf="source.type === 'mediaconnect'">{{ "FROM_MEDIACONNECT" | translate }}</span>
        <span *ngIf="source.type === 'udp' || source.type === 'rtp'">{{ source.type | translate }}</span>
        <span *ngIf="source.type === 'srt' && source.srt_mode === 'pull'">{{ "SRT_PULL" | translate }}</span>
        <span *ngIf="source.type === 'srt' && source.srt_mode === 'push'">{{ "SRT_PUSH" | translate }}</span>
        <span *ngIf="source.type === 'rist'">{{ "RIST_SIMPLE" | translate }}</span>
        <span *ngIf="source.type === 'rist_pull'">{{ "RIST_PULL_FULL" | translate }}</span>
        <span *ngIf="source.type === 'rist_push'">{{ "RIST_PUSH_FULL" | translate }}</span>
        <span *ngIf="source.type === 'zixi_pull'">{{ "ZIXI_PULL_FULL" | translate }}</span>
        <span *ngIf="source.type === 'pid_map'">{{ "PID_MAPPING" | translate }}</span>
        <span *ngIf="source.type === 'file'">{{ "FILE" | translate }}</span>
        <span *ngIf="source.type === 'ndi'">{{ "NDI" | translate }}</span>
        <span *ngIf="source.type === 'rtmp' && source.pull_mode">{{ "RTMP_PULL_SOURCE" | translate }}</span>
        <span *ngIf="source.type === 'rtmp' && !source.pull_mode">{{ "RTMP_PUSH_SOURCE" | translate }}</span>
        <span *ngIf="source.type === 'hls_pull'">{{ "HLS_PULL" | translate }}</span>
    </div>
</div>

<div
    class="form-group row"
    *ngIf="source.feeder_id || source.broadcaster_id || source.input_id || source.transcode_profile_id || source.Source || source._frontData.input_description"
>
    <h5 class="col-12" translate>INPUT</h5>
    <div class="col-12">
        <zx-feeder *ngIf="source.feeder_id" [model]="source.feeder" [showTag]="true" [showLink]="true" [showStatusIcon]="false"></zx-feeder>
        <zx-broadcaster *ngIf="source.broadcaster_id" [model]="source.broadcaster" [showTag]="true" [showLink]="true" [showStatusIcon]="false"></zx-broadcaster>
        <span *ngIf="source.input_id && source.type !== 'zixi_pull'"
            ><span *ngIf="source.broadcaster_id || source.feeder_id">&nbsp;/&nbsp;</span>{{ source.input_id
            }}<span *ngIf="source.Source">&nbsp;/&nbsp;</span></span
        >
        <span *ngFor="let failoverSource of source.failoverSources; let isLast = last">{{ failoverSource.source.name }}{{ isLast ? "" : ",&nbsp;" }}</span>
        <span *ngIf="source.transcode_profile_id && source.transcodeSource && source.transcodeSource.name">
            {{ source.transcodeSource.name }}
        </span>
        <zx-source *ngIf="source.Source" [model]="source.Source" [showLink]="true" [showTag]="false"></zx-source>
        <span *ngIf="source._frontData.input_description">
            {{ source._frontData.input_description }}
        </span>
    </div>
</div>

<div class="form-group row" *ngIf="primaryFailoverSources.length > 0">
    <h5 class="col-12">{{ "PRIMARY" | translate }} {{ "SOURCES" | translate }}</h5>
    <div class="col-12">
        <div *ngFor="let source of primaryFailoverSources">
            <zx-source [model]="source" [showLink]="true" [showTag]="false"></zx-source>
        </div>
    </div>
</div>
<div class="form-group row" *ngIf="secondaryFailoverSources.length > 0">
    <h5 class="col-12">{{ "SECONDARY" | translate }} {{ "SOURCES" | translate }}</h5>
    <div class="col-12">
        <div *ngFor="let source of secondaryFailoverSources">
            <zx-source [model]="source" [showLink]="true" [showTag]="false"></zx-source>
        </div>
    </div>
</div>
<div class="form-group row" *ngIf="slateFailoverSources.length > 0">
    <h5 class="col-12">{{ "SLATE" | translate }} {{ "SOURCES" | translate }}</h5>
    <div class="col-12">
        <div *ngFor="let source of slateFailoverSources">
            <zx-source [model]="source" [showLink]="true" [showTag]="false"></zx-source>
        </div>
    </div>
</div>

<div class="form-group row" *ngIf="source.target_broadcaster_id && !targetBXsLoading">
    <h5 class="col-12" translate>TARGET_BROADCASTER/S</h5>
    <div class="col-12">
        <span *ngIf="source.target_broadcaster_id === -1">{{ "PREFER_PRIMARY_BROADCASTERS" | translate }}</span>
        <span *ngIf="source.target_broadcaster_id === -2">{{ "PRIMARY_BROADCASTERS_ONLY" | translate }}</span>
        <span *ngIf="source.target_broadcaster_id === -3">{{ "BACKUP_BROADCASTERS_ONLY" | translate }}</span>
        <span *ngIf="source.target_broadcaster_id !== -3 && source.target_broadcaster_id !== -2 && source.target_broadcaster_id !== -1">
            {{ targetBroadcaster.name }}
        </span>
    </div>
</div>

<div class="form-group row" *ngIf="source.transcodeProfile">
    <h5 class="col-12" translate>TRANSCODING_PROFILE</h5>
    <div class="col-12">
        <a [routerLink]="['/' + urls.transformation.transcoding_profiles, source.transcodeProfile.name]">
            {{ source.transcodeProfile.name }}
        </a>
    </div>
</div>

<div class="form-group row" *ngIf="source.pid_mapping && source.pid_mapping.name">
    <h5 class="col-12" translate>PID_MAPPING</h5>
    <div class="col-12">
        <a [routerLink]="['/' + urls.transformation.pid_mapping_profiles, source.pid_mapping.name]">
            {{ source.pid_mapping.name }}
        </a>
    </div>
</div>

<div class="form-group row" *ngIf="source.feeder_id">
    <h5 class="col-12" translate>MAX_BITRATE</h5>
    <div class="col-12">
        <span>{{ source.max_bitrate | bitrate }}</span>
    </div>
</div>

<div class="form-group row" *ngIf="source.feeder_id">
    <h5 class="col-12">{{ "LATENCY" | translate }} [ms]</h5>
    <div class="col-12">
        <span>{{ source.latency }}</span>
    </div>
</div>

<div class="form-group row" *ngIf="source.status && source.status.source_ip">
    <h5 class="col-12" translate>SOURCE_IP</h5>
    <div class="col-12">
        <span>{{ source.status.source_ip }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="source.status && source.status.bitrate">
    <h5 class="col-12" translate>BITRATE</h5>
    <div class="col-12">
        <span>{{ source.status.bitrate | number : "1.0-0" }} kbps</span>
    </div>
</div>
<div class="form-group row" *ngIf="source.status && source.status.up_time">
    <h5 class="col-12">{{ "UP_TIME" | translate }} [{{ "HH:mm:ss" | translate }}]</h5>
    <div class="col-12">
        <span>{{ source.status.up_time | uptime }}</span>
    </div>
</div>

<div
    class="form-group row"
    *ngIf="
        source &&
        source.type !== 'file' &&
        ((source._frontData.cluster?.auth_param && source._frontData.cluster?.auth_mode === 'password') ||
            (source.password && source._frontData.cluster?.auth_mode !== 'password')) &&
        (!source._frontData.cluster?.auth_mode ||
            source._frontData.cluster?.auth_mode === 'zen' ||
            source._frontData.cluster?.auth_mode === 'password' ||
            source._frontData.cluster?.auth_mode === 'custom')
    "
>
    <h5 class="col-12" translate>SOURCE_PASSWORD</h5>
    <div class="col-12" *ngIf="source._frontData.cluster?.auth_mode === 'password'">
        <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
            {{ "SHOW" | translate }}
        </a>
        <span *ngIf="pwVisible">{{ source._frontData.cluster?.auth_param }}</span> /
        <a href="javascript:void(0)" (click)="copyPassword(source._frontData.cluster?.auth_param)">
            {{ "COPY" | translate }}
        </a>
    </div>
    <div class="col-12" *ngIf="source._frontData.cluster?.auth_mode !== 'password'">
        <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
            {{ "SHOW" | translate }}
        </a>
        <span *ngIf="pwVisible">{{ source.password }}</span> /
        <a href="javascript:void(0)" (click)="copyPassword(source.password)">
            {{ "COPY" | translate }}
        </a>
    </div>
</div>

<div class="form-group row">
    <h5 class="col-12" translate>PUBLIC_OUTPUT</h5>
    <div class="col-12">
        <span *ngIf="!source.allow_outputs">{{ "DISABLED" | translate }}</span>
        <span *ngIf="source.allow_outputs">{{ source.public_url }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="source.type !== 'file'">
    <h5 class="col-12" translate>ALERT_ON_PID_CHANGES</h5>
    <div class="col-12">
        <span *ngIf="source.monitor_pids_change">{{ "ENABLED" | translate }}</span>
        <span *ngIf="!source.monitor_pids_change">{{ "DISABLED" | translate }}</span>
    </div>
</div>
<div class="form-group row">
    <h5 class="col-12" translate>CONTENT_ANALYSIS</h5>
    <div class="col-12">
        <span *ngIf="source.content_analysis">{{ "ENABLED" | translate }}</span>
        <span *ngIf="!source.content_analysis">{{ "DISABLED" | translate }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="source.process_scte_reports && source.type !== 'file'">
    <h5 class="col-12" translate>LOG_SCTE_35</h5>
    <div class="col-12">
        <span *ngIf="source.inputCluster.configure_scte_reports">{{ "ENABLED" | translate }}</span>
        <span *ngIf="!source.inputCluster.configure_scte_reports">{{ "DISABLED" | translate }} {{ "ON" | translate }} {{ "CLUSTER" | translate }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="source.type !== 'file'">
    <h5 class="col-12" translate>SCTE_35_ALERTS</h5>
    <div class="col-12">
        <span *ngIf="source.report_scte_warnings">{{ "ENABLED" | translate }}</span>
        <span *ngIf="!source.report_scte_warnings">{{ "DISABLED" | translate }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="source.disable_autopull">
    <h5 class="col-12" translate>AUTO_PULL</h5>
    <div class="col-12">
        <span>{{ "DISABLED" | translate }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="!source.disable_autopull && source.autopull_latency">
    <h5 class="col-12">{{ "AUTO_PULL" | translate }} {{ "LATENCY" | translate }} [ms]</h5>
    <div class="col-12">
        <span>{{ source.autopull_latency }}</span>
    </div>
</div>

<!-- Location -->
<app-details-location [location]="source.location"></app-details-location>
<div
    class="form-group row mt-2"
    *ngIf="
        !source.location ||
        !source.location?.address ||
        source.location?.address.latitude !== dataMarkerLatLng().lat ||
        source.location?.address.longitude !== dataMarkerLatLng().lng
    "
>
    <h5 class="col-12" translate>LAT/LNG</h5>
    <div class="col-12">
        <span title="{{ 'LAT' | translate }}: {{ dataMarkerLatLng().lat }}, {{ 'LNG' | translate }}: {{ dataMarkerLatLng().lng }}"
            >{{ "LAT" | translate }}: {{ dataMarkerLatLng().lat }}, {{ "LNG" | translate }}: {{ dataMarkerLatLng().lng }}</span
        >
    </div>
</div>
