<div class="form-group row">
    <div class="col">
        <zx-target [model]="target" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="true"></zx-target>
    </div>
</div>
<!-- Access Tags -->
<div class="form-group row" *ngIf="target.target.resourceTags && target.target.resourceTags.length">
    <h5 class="col-12" translate>TAGS</h5>
    <div class="col-12">
        <span *ngFor="let tag of target.target.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="target.target.alertingProfile">
    <h5 class="col-12" translate>ALERTING_PROFILE</h5>
    <div class="col-12">
        <span>{{ target.target.alertingProfile.name }}</span>
    </div>
</div>
<!-- Type -->
<div class="form-group row">
    <h5 class="col-12" translate>TYPE</h5>
    <div class="col-12">
        <span>{{ target.type_name }}</span>
    </div>
</div>
<!-- -->
<!-- Active Broadcaster -->
<div class="form-group row" *ngIf="target._frontData?.active_broadcaster as activeBroadcaster">
    <h5 class="col-12" translate>ACTIVE_BROADCASTER</h5>
    <div class="col-12">
        <zx-broadcaster-link
            [id]="activeBroadcaster.id"
            [name]="activeBroadcaster.name"
            [clusterId]="target.target.deliveryChannel?.broadcaster_cluster_id"
        ></zx-broadcaster-link>
    </div>
</div>
<!-- Active Broadcasters -->
<div class="form-group row" *ngIf="target._frontData?.active_broadcaster && !target._frontData?.active_broadcaster.id">
    <h5 class="col-12" translate>ACTIVE_BROADCASTERS</h5>
    <div class="col-12">
        <div *ngFor="let broadcaster of target.target.status?.active_broadcasters">
            <zx-broadcaster
                [model]="broadcaster"
                [showTag]="false"
                [showLink]="true"
                [showStatusIcon]="false"
                [clusterId]="target.target.deliveryChannel?.broadcaster_cluster_id"
            ></zx-broadcaster>
        </div>
    </div>
</div>
<!-- -->
<!-- HTTP -->
<div *ngIf="TargetsTypeGuard.isPublishing(target.target)">
    <!-- Channel -->
    <div class="form-group row">
        <h5 class="col-12" translate>CHANNEL</h5>
        <div class="col-12">
            <zx-adaptive [model]="target.target.adaptiveChannel" [showLink]="true" *ngIf="target.target.adaptive_channel_id != null"></zx-adaptive>
            <span *ngIf="target.target.adaptive_channel_id == null">-</span>
        </div>
    </div>
    <!-- s3/mediastore URI -->
    <div class="form-group row" *ngIf="target.target.type === 's3' || target.target.type === 'mediastore'">
        <h5 class="col-12" translate *ngIf="target.target.type === 's3'">S3_URI</h5>
        <h5 class="col-12" translate *ngIf="target.target.type === 'mediastore'">MEDIASTORE_URI</h5>
        <div class="col-12">
            <span>{{ target.target.ingest_url }}</span>
        </div>
    </div>
    <!-- Encapsulation -->
    <div class="form-group row">
        <h5 class="col-12" translate>ENCAPSULATION</h5>
        <div class="col-12">
            <span>{{ target.target.encapsulation | uppercase }}</span>
        </div>
    </div>
    <!-- s3/mediastore Region -->
    <div class="form-group row" *ngIf="target.target.type === 's3' || target.target.type === 'mediastore'">
        <h5 class="col-12" translate>REGION</h5>
        <div class="col-12">
            <span>{{ target.target.aws_region }}</span>
        </div>
    </div>
    <!-- s3/mediastore Access Key ID -->
    <div class="form-group row" *ngIf="target.target.type === 's3' || target.target.type === 'mediastore'">
        <h5 class="col-12" translate>ACCESS_KEY_ID</h5>
        <div class="col-12">
            <span>{{ target.target.aws_access_key_id }}</span>
        </div>
    </div>
    <!-- Ingest URL -->
    <div class="form-group row" *ngIf="target.target.type !== 's3' && target.target.type !== 'mediastore'">
        <h5 class="col-12" translate>INGEST_URL</h5>
        <div class="col-12">
            <span>{{ target.target.ingest_url }}</span>
        </div>
    </div>
    <!-- Playback URL -->
    <div class="form-group row" *ngIf="target.target.playback_url && target.target.playback_url !== ''">
        <h5 class="col-12" translate>PLAYBACK_URL</h5>
        <div class="col-12">
            <span>{{ target.target.playback_url }}</span>
        </div>
    </div>
    <!-- Play HLS -->
    <div
        class="form-group row"
        *ngIf="!target.target.playback_url && (target.target.type === 's3' || target.target.type === 'mediastore' || target.target.type === 'azure')"
    >
        <h5 class="col-12" translate>PLAY_HLS</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="targetsService.openTargetHLSPreview(target.target)" class="btn btn-play btn-primary"
                ><fa-icon icon="play" size="xs"></fa-icon
            ></a>
        </div>
    </div>
    <!-- Play Target-->
    <div class="form-group row" *ngIf="target.target.playback_url">
        <h5 class="col-12" translate>PLAY_TARGET</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="targetsService.openTargetPreview(target.target)" class="btn btn-play btn-primary"
                ><fa-icon icon="play" size="xs"></fa-icon
            ></a>
        </div>
    </div>
    <!-- Delete Outdated -->
    <div class="form-group row">
        <h5 class="col-12" translate>DELETE_OUTDATED</h5>
        <div class="col-12">
            <span>{{ (target.target.delete_outdated ? "YES" : "NO") | translate }}</span>
        </div>
    </div>
    <!-- Ignore Certificate -->
    <div class="form-group row">
        <h5 class="col-12" translate>IGNORE_CERTIFICATE</h5>
        <div class="col-12">
            <span> {{ (target.target.ignore_certificate ? "YES" : "NO") | translate }}</span>
        </div>
    </div>
</div>
<!-- Pull -->
<div *ngIf="TargetsTypeGuard.isZixiPull(target.target)">
    <!-- Channel -->
    <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id == null">
        <h5 class="col-12" translate>CHANNEL</h5>
        <div class="col-12">
            <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
            <span *ngIf="target.target.delivery_channel_id == null">-</span>
        </div>
    </div>
    <!-- MediaConnect Flow -->
    <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
        <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
        <div class="col-12">
            <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
        </div>
    </div>
    <!-- Receiver -->
    <div class="form-group row" *ngIf="target.target.receiver_id">
        <h5 class="col-12" translate>RECEIVER</h5>
        <div class="col-12">
            <div>
                <zx-receiver [model]="target.target.receiver" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="true"></zx-receiver>
                <span *ngIf="target.target.output_id">&nbsp;/&nbsp;{{ target.target.output_name }}</span>
            </div>
        </div>
    </div>
    <!-- Broadcaster -->
    <div class="form-group row" *ngIf="target.target.broadcaster_id">
        <h5 class="col-12" translate>BROADCASTER</h5>
        <div class="col-12">
            <div>
                <zx-broadcaster
                    [model]="target.target.broadcaster"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="true"
                ></zx-broadcaster>
                <span *ngIf="target.target.output_id">&nbsp;/&nbsp;{{ target.target.output_name }}</span>
            </div>
        </div>
    </div>
    <!-- Receiver Name/ID -->
    <div class="form-group row" *ngIf="target.target.receiver_name">
        <h5 class="col-12" translate>RECEIVER_NAME_ID</h5>
        <div class="col-12">
            <span>{{ target.target.receiver_name }}</span>
        </div>
    </div>
    <!-- Password -->
    <div class="form-group row" *ngIf="target.target.password">
        <h5 class="col-12" translate>PASSWORD</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
            ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
            <a href="javascript:void(0)" (click)="copyPassword(target.target.password)">
                {{ "COPY" | translate }}
            </a>
        </div>
    </div>
    <!-- Encryption Key -->
    <div class="form-group row" *ngIf="target.target.zixi_encryption_key">
        <h5 class="col-12" translate>ENCRYPTION_KEY</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="keyVisible = true" *ngIf="!keyVisible">
                {{ "SHOW" | translate }}
            </a>
            <span *ngIf="keyVisible">{{ target.target.zixi_encryption_key }}</span> /
            <a href="javascript:void(0)" (click)="copyPassword(target.target.zixi_encryption_key)">
                {{ "COPY" | translate }}
            </a>
        </div>
    </div>
    <!-- Latency -->
    <div class="form-group row">
        <h5 class="col-12" translate>LATENCY_MS</h5>
        <div class="col-12">
            <span>{{ target.target.latency || ("REMOTE_CONFIGURATION" | translate) }}</span>
        </div>
    </div>
</div>
<!-- SRT -->
<div *ngIf="TargetsTypeGuard.isSrt(target.target)">
    <!-- Channel -->
    <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id == null">
        <h5 class="col-12" translate>CHANNEL</h5>
        <div class="col-12">
            <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
            <span *ngIf="target.target.delivery_channel_id == null">-</span>
        </div>
    </div>
    <!-- MediaConnect Flow -->
    <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
        <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
        <div class="col-12">
            <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
        </div>
    </div>
    <!-- Bind CIDR -->
    <div class="form-group row" *ngIf="target.target.bind_cidr">
        <h5 class="col-12" translate>BIND_TO_LOCAL_NIC_BY_CIDR</h5>
        <div class="col-12">
            <span>{{ target.target.bind_cidr }} </span>
        </div>
    </div>
    <!-- Latency -->
    <div class="form-group row">
        <h5 class="col-12" translate>LATENCY_MS</h5>
        <div class="col-12">
            <span>{{ target.target.latency }} </span>
        </div>
    </div>
    <!-- Password -->
    <div class="form-group row">
        <h5 class="col-12" translate>PASSWORD</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
            ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
            <a href="javascript:void(0)" (click)="copyPassword(target.target.password)">
                {{ "COPY" | translate }}
            </a>
        </div>
    </div>
    <!-- Encryption -->
    <div class="form-group row">
        <h5 class="col-12" translate>ENCRYPTION</h5>
        <div class="col-12">
            <span>{{ (target.target.encryption ? target.target.encryption : "DISABLED") | translate }}</span>
        </div>
    </div>
</div>
<!-- Push -->
<div *ngIf="TargetsTypeGuard.isZixiPush(target.target)">
    <!-- Channel -->
    <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id == null">
        <h5 class="col-12" translate>CHANNEL</h5>
        <div class="col-12">
            <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
            <span *ngIf="target.target.delivery_channel_id == null">-</span>
        </div>
    </div>
    <!-- MediaConnect Flow -->
    <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
        <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
        <div class="col-12">
            <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
        </div>
    </div>
    <!-- Target Host -->
    <div class="form-group row">
        <h5 class="col-12" translate>TARGET_HOST</h5>
        <div class="col-12">
            <span>{{ target.target.target }}</span>
        </div>
    </div>
    <!-- Alt Target Host -->
    <div class="form-group row" *ngIf="target.target.alt_target">
        <h5 class="col-12" translate>ALT_TARGET_HOST</h5>
        <div class="col-12">
            <span>{{ target.target.alt_target }}</span>
        </div>
    </div>
    <!-- Stream ID -->
    <div class="form-group row">
        <h5 class="col-12" translate>STREAM_ID</h5>
        <div class="col-12">
            <span>{{ target.target.stream_id }}</span>
        </div>
    </div>
    <!-- Password -->
    <div class="form-group row">
        <h5 class="col-12" translate>PASSWORD</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
            ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
            <a href="javascript:void(0)" (click)="copyPassword(target.target.password)">
                {{ "COPY" | translate }}
            </a>
        </div>
    </div>
    <!-- Latency -->
    <div class="form-group row">
        <h5 class="col-12" translate>LATENCY_MS</h5>
        <div class="col-12">
            <span>{{ target.target.latency }}</span>
        </div>
    </div>
    <!-- Ignore DTLS certificate -->
    <div class="form-group row">
        <h5 class="col-12" translate>IGNORE_DTLS_CERTIFICATE</h5>
        <div class="col-12">
            <span>{{ (target.target.ignore_dtls_cert ? "YES" : "NO") | translate }}</span>
        </div>
    </div>
</div>
<!-- UDP/RTP/RIST -->
<div *ngIf="TargetsTypeGuard.isUdpRtp(target.target) || TargetsTypeGuard.isRist(target.target)">
    <!-- Channel -->
    <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id == null">
        <h5 class="col-12" translate>CHANNEL</h5>
        <div class="col-12">
            <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
            <span *ngIf="target.target.delivery_channel_id == null">-</span>
        </div>
    </div>
    <!-- MediaConnect Flow -->
    <div class="form-group row" *ngIf="target.target.mediaconnect_flow_id != null">
        <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
        <div class="col-12">
            <zx-mediaconnect-flow [model]="target.target.mediaconnectFlow" [showLink]="true"></zx-mediaconnect-flow>
        </div>
    </div>
    <!-- Target -->
    <div class="form-group row">
        <h5 class="col-12" translate>TARGET</h5>
        <div class="col-12">
            <span>{{ target.target.host }}:{{ target.target.port }}</span>
        </div>
    </div>
    <!-- Local NIC CIDR -->
    <div class="form-group row" *ngIf="target.target.bind_cidr">
        <h5 class="col-12" translate>LOCAL_NIC_CIDR</h5>
        <div class="col-12">
            <span>{{ target.target.bind_cidr }}</span>
        </div>
    </div>
    <!-- SMPTE 2022 FEC -->
    <div class="form-group row" *ngIf="target.target.smpte_2022_fec > 0">
        <h5 class="col-12" translate>SMPTE_2022_FEC</h5>
        <div class="col-12">
            <span> {{ target.target.smpte_2022_fec }}D ({{ target.target.smpte_2022_fec_rows }}x{{ target.target.smpte_2022_fec_cols }})</span>
        </div>
    </div>
    <!-- Target -->
    <div class="form-group row" *ngIf="target.target.remux_kbps">
        <h5 class="col-12" translate>REMUX</h5>
        <div class="col-12">
            <span>{{ target.target.remux_kbps | number : "1.0-0" }} kbps</span>
        </div>
    </div>
    <!-- Smoothing -->
    <div class="form-group row" *ngIf="target.target.smoothing">
        <h5 class="col-12" translate>SMOOTHING</h5>
        <div class="col-12">
            <span>{{ target.target.smoothing }}ms</span>
        </div>
    </div>
</div>
<!-- RTMP -->
<div *ngIf="TargetsTypeGuard.isRtmpPush(target.target)">
    <!-- Channel -->
    <div class="form-group row">
        <h5 class="col-12" translate>CHANNEL</h5>
        <div class="col-12">
            <zx-delivery [model]="target.target.deliveryChannel" [showLink]="true" *ngIf="target.target.delivery_channel_id != null"></zx-delivery>
            <span *ngIf="target.target.delivery_channel_id == null">-</span>
        </div>
    </div>
    <!-- Target -->
    <div class="form-group row">
        <h5 class="col-12" translate>TARGET</h5>
        <div class="col-12">
            <span>{{ target.target.target }}</span>
        </div>
    </div>
    <!-- Alt Target -->
    <div class="form-group row" *ngIf="target.target.alt_target">
        <h5 class="col-12" translate>ALT_TARGET</h5>
        <div class="col-12">
            <span>{{ target.target.alt_target }}</span>
        </div>
    </div>
    <!-- Stream Name -->
    <div class="form-group row">
        <h5 class="col-12" translate>STREAM_NAME</h5>
        <div class="col-12">
            <span>{{ target.target.stream_name }}</span>
        </div>
    </div>
    <!-- Username -->
    <div class="form-group row">
        <h5 class="col-12" translate>USERNAME</h5>
        <div class="col-12">
            <span>{{ target.target.username }}</span>
        </div>
    </div>
    <!-- Password -->
    <div class="form-group row">
        <h5 class="col-12" translate>PASSWORD</h5>
        <div class="col-12">
            <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a
            ><span *ngIf="pwVisible">{{ target.target.password }}</span> /
            <a href="javascript:void(0)" (click)="copyPassword(target.target.password)">
                {{ "COPY" | translate }}
            </a>
        </div>
    </div>
    <!-- Bitrate -->
    <div class="form-group row">
        <h5 class="col-12" translate>BITRATE_KBPS</h5>
        <div class="col-12">
            <span>{{ target.target.kbps || "Auto Detect" }}</span>
        </div>
    </div>
</div>

<!-- Location -->
<app-details-location [location]="target.target.location"></app-details-location>
<div
    class="form-group row mt-2"
    *ngIf="
        !target.target.location ||
        !target.target.location?.address ||
        target.target.location?.address.latitude !== dataMarkerLatLng().lat ||
        target.target.location?.address.longitude !== dataMarkerLatLng().lng
    "
>
    <h5 class="col-12" translate>LAT/LNG</h5>
    <div class="col-12">
        <span title="{{ 'LAT' | translate }}: {{ dataMarkerLatLng().lat }}, {{ 'LNG' | translate }}: {{ dataMarkerLatLng().lng }}"
            >{{ "LAT" | translate }}: {{ dataMarkerLatLng().lat }}, {{ "LNG" | translate }}: {{ dataMarkerLatLng().lng }}</span
        >
    </div>
</div>
